import StraightenIcon from "@mui/icons-material/Straighten";
import { Box, IconButton, Stack, Tooltip, useTheme } from "@mui/material";
import { Position } from "geojson";
import { Point } from "geometric";
import * as React from "react";
import { useAsync } from "react-use";
import { v4 as uuidv4 } from "uuid";
import { useApi } from "../../../../containers/ApiContainer";
import { Marking, MarkingType, Ramp } from "../../../../types";
import Canvas from "../../../../widgets/useCanvas";
import { RampCanvasToolbar } from "./RampCanvasToolbar";

type Props = {
  ramp: Ramp;
  setRamp: (ramp: Ramp) => void;
  showReferenceImage: boolean;
  readOnly?: boolean;
};

const distance = (p1: Position, p2: Position) => {
  const [x1, y1] = p1;
  const [x2, y2] = p2;
  return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
};

const smoothLine = (points: Position[]): Position[] => {
  const smoothedPoints: Point[] = [];

  // Implement Catmull-Rom spline algorithm
  for (let i = 0; i < points.length; i++) {
    let p0: Position = points[i - 1] || points[i];
    let p1: Position = points[i];
    let p2: Position = points[i + 1] || points[i];
    let p3: Position = points[i + 2] || points[i];

    for (let t = 0; t <= 1; t += 0.1) {
      let x: number =
        0.5 *
        (2 * p1[0] +
          (p2[0] - p0[0]) * t +
          (2 * p0[0] - 5 * p1[0] + 4 * p2[0] - p3[0]) * t * t +
          (3 * p1[0] - p0[0] - 3 * p2[0] + p3[0]) * t * t * t);
      let y: number =
        0.5 *
        (2 * p1[1] +
          (p2[1] - p0[1]) * t +
          (2 * p0[1] - 5 * p1[1] + 4 * p2[1] - p3[1]) * t * t +
          (3 * p1[1] - p0[1] - 3 * p2[1] + p3[1]) * t * t * t);
      smoothedPoints.push([x, y]);
    }
  }
  return smoothedPoints;
};

export type CanvasStyleOptions = {
  fillStyle?: string;
  strokeStyle?: string;
  lineWidth?: string;
};

export const traversePoints = (
  ctx: CanvasRenderingContext2D,
  points: Position[],
  style: any,
  feetToPixels: (ft: number) => number,
  closePolygon: boolean,
  readOnly: boolean
) => {
  let isFirst = true;
  for (const point of points) {
    const pointInPixels = point.map(feetToPixels);
    if (!readOnly) {
      ctx.fillRect(
        pointInPixels[0] - style.lineWidth,
        ctx.canvas.height - pointInPixels[1] - style.lineWidth,
        style.lineWidth * 2,
        style.lineWidth * 2
      );
    }
    if (isFirst) {
      ctx.moveTo(pointInPixels[0], ctx.canvas.height - pointInPixels[1]);
      isFirst = false;
    } else {
      ctx.lineTo(pointInPixels[0], ctx.canvas.height - pointInPixels[1]);
    }
  }
  if (closePolygon && points?.length) {
    const pointInPixels = points[0].map(feetToPixels);
    ctx.lineTo(pointInPixels[0], ctx.canvas.height - pointInPixels[1]);
  }
};

export const drawMarking = (
  ctx: CanvasRenderingContext2D,
  marking: Marking,
  feetToPixels: (ft: number) => number,
  readOnly: boolean,
  styleOverrides: any = {}
) => {
  ctx.beginPath();
  ctx.save();

  const style = {
    fillStyle: "magenta",
    strokeStyle: "magenta",
    lineWidth: 2,
    lineCap: null,
  };

  if (marking.type === MarkingType.OUTLINE) {
    style.fillStyle = "#3c6ce9";
    style.strokeStyle = "#3c6ce9";
    style.lineWidth = readOnly ? 2 : 2;
  } else if (marking.type === MarkingType.RED_SOLID) {
    style.strokeStyle = "red";
    style.fillStyle = "red";
    style.lineWidth = readOnly ? 2 : 2;
  } else if (marking.type === MarkingType.GREY) {
    style.strokeStyle = "grey";
    style.fillStyle = "grey";
    style.lineWidth = readOnly ? 2 : 2;
  } else if (marking.type === MarkingType.WHITE_SOLID) {
    style.strokeStyle = "#FCFAFF";
    style.fillStyle = "#FCFAFF";
    style.lineWidth = readOnly ? 2 : 2;
  } else if (marking.type === MarkingType.WHITE_DASHED) {
    style.strokeStyle = "#FCFAFF";
    style.fillStyle = "#FCFAFF";
    style.lineWidth = readOnly ? 2 : 2;
    ctx.setLineDash([10]);
  } else if (marking.type === MarkingType.YELLOW_SOLID) {
    style.strokeStyle = "#eed202";
    style.fillStyle = "#eed202";
    style.lineWidth = readOnly ? 2 : 2;
  } else if (marking.type === MarkingType.YELLOW_SOLID_CURVED) {
    style.strokeStyle = "#eed202";
    style.fillStyle = "#eed202";
    style.lineWidth = readOnly ? 2 : 2;
    style.lineCap = "round";
  } else if (marking.type === MarkingType.YELLOW_DASHED) {
    style.strokeStyle = "#eed202";
    style.fillStyle = "#eed202";
    style.lineWidth = readOnly ? 2 : 2;
    ctx.setLineDash([10]);
  } else if (marking.type === MarkingType.REFERENCE_DISTANCE) {
    style.strokeStyle = "#e3c783";
    style.fillStyle = "#e3c783";
    style.lineWidth = readOnly ? 2 : 2;
  } else {
    ctx.restore();
    return;
  }

  ctx.fillStyle = marking.selected ? "hotpink" : style.fillStyle;
  ctx.strokeStyle = marking.selected ? "hotpink" : style.strokeStyle;
  ctx.lineWidth = style.lineWidth;

  const points = marking?.geom.coordinates.flat() ?? [];

  traversePoints(
    ctx,
    points,
    style,
    feetToPixels,
    marking.type === MarkingType.OUTLINE,
    readOnly
  );
  ctx.stroke();

  if (marking.type === MarkingType.OUTLINE) {
    ctx.beginPath();
    traversePoints(ctx, points, style, feetToPixels, true, readOnly);
    ctx.fillStyle = styleOverrides.fillStyle ?? "#D3D3D360";
    ctx.fill();
  }
  ctx.restore();
};

type ReadOnlyProps = {
  width: number;
  ramp: Ramp;
  backgroundColor: string;
  showReferenceImage?: boolean;
};

export const RampCanvasReadOnly: React.FC<ReadOnlyProps> = ({
  width,
  ramp,
  backgroundColor,
  showReferenceImage = false,
}) => {
  const theme = useTheme();
  const { postgrest } = useApi();
  const [measuringToolActive, setMeasuringToolActive] = React.useState<boolean>(
    false
  );
  const [measuringToolState, setMeasuringToolState] = React.useState<number>(0);
  const canvasRef = React.createRef<HTMLCanvasElement>();
  const [startPoint, setStartPoint] = React.useState<{
    x: number;
    y: number;
  } | null>(null);
  const [endPoint, setEndPoint] = React.useState<{
    x: number;
    y: number;
  } | null>(null);

  const pixelsToFeetRatio = (ramp?.width ?? 1) / width;
  const canvasWidth = width;
  const canvasHeight = (canvasWidth * ramp?.depth) / ramp?.width;
  const feetToPixels = (feet: number) => feet / pixelsToFeetRatio;

  const rampImage = useAsync(async () => {
    if (ramp.id && showReferenceImage) {
      const { data } = await postgrest
        .from("ramp")
        .select("reference_image")
        .eq("id", ramp.id)
        .single();
      return data.reference_image;
    }
    return null;
  }, [showReferenceImage, ramp.reference_image]);

  const draw = React.useCallback(
    (ctx: CanvasRenderingContext2D) => {
      ctx.clearRect(0, 0, canvasWidth, canvasHeight);
      drawMeasurement();
      // draw stuff!
      if (ramp?.outline) {
        drawMarking(
          ctx,
          {
            id: uuidv4(),
            ramp_id: ramp.id,
            type: MarkingType.OUTLINE,
            geom: ramp.outline,
          },
          feetToPixels,
          true,
          { fillStyle: backgroundColor }
        );
      }

      for (const marking of ramp.markings) {
        drawMarking(ctx, marking, feetToPixels, true);
      }
    },
    [ramp, width, startPoint, endPoint, measuringToolState]
  );

  const handleCanvasClick = (e: React.MouseEvent<HTMLCanvasElement>) => {
    if (!measuringToolActive) {
      return;
    }
    const rect = canvasRef.current!.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    if (!startPoint) {
      // First click - store the starting point
      setStartPoint({ x, y });
      setMeasuringToolState(1);
    } else if (endPoint && measuringToolState === 1) {
      setMeasuringToolState(2);
    } else if (endPoint && measuringToolState === 2) {
      // Second click - store the end point and calculate the distance
      clearMeasurements();
      setMeasuringToolState(0);
    }
  };

  const clearMeasurements = () => {
    setStartPoint(null);
    setEndPoint(null);
    const ctx = canvasRef.current!.getContext("2d");
    ctx?.clearRect(0, 0, canvasRef.current!.width, canvasRef.current!.height);
  };

  const drawMeasurement = React.useCallback(() => {
    if (!measuringToolActive) {
      return;
    }
    if (!startPoint || !endPoint) {
      return;
    }

    const ctx = canvasRef.current?.getContext("2d");
    if (!ctx) {
      return;
    }

    // Draw the line between the two points
    ctx.beginPath();
    ctx.moveTo(startPoint.x, startPoint.y);
    ctx.lineTo(endPoint.x, endPoint.y);
    ctx.stroke();

    // Calculate distance in pixels
    const pixelDistance = Math.sqrt(
      Math.pow(endPoint.x - startPoint.x, 2) +
        Math.pow(endPoint.y - startPoint.y, 2)
    );

    // Convert to feet
    const distanceInFeet = pixelDistance * pixelsToFeetRatio;

    // Display the distance
    ctx.font = "16px Arial";
    ctx.lineWidth = 2;
    ctx.strokeStyle = "black";
    ctx.setLineDash([5, 5]);
    ctx.fillStyle = "black";
    ctx.fillText(
      `${distanceInFeet.toFixed(2)} feet`,
      (startPoint.x + endPoint.x) / 2,
      (startPoint.y + endPoint.y) / 2
    );
  }, [startPoint, endPoint, measuringToolState]);

  return (
    <Box
      sx={{
        cursor: "inherit",
        position: "relative",
        backgroundColor: "transparent",
      }}
    >
      <Canvas
        ref={canvasRef}
        width={canvasWidth}
        height={canvasHeight}
        draw={draw}
        onClick={handleCanvasClick}
        onMouseMove={(e: React.MouseEvent<HTMLCanvasElement>) => {
          if (startPoint && measuringToolState < 2) {
            const rect = canvasRef.current!.getBoundingClientRect();
            const x = e.clientX - rect.left;
            const y = e.clientY - rect.top;
            setEndPoint({ x, y });
          }
          if (startPoint && endPoint) {
            drawMeasurement();
          }
        }}
        style={{
          backgroundImage: showReferenceImage && `url(${rampImage?.value})`,
          backgroundSize: showReferenceImage && "cover",
        }}
      />
      <Box
        sx={{
          position: "fixed",
          bottom: 104,
          right: 16,
          zIndex: theme.zIndex.mobileStepper - 1,
          backgroundColor: "white",
        }}
      >
        <Tooltip title="Measuring Tool" placement="left">
          <IconButton
            onClick={() => {
              setStartPoint(null);
              setEndPoint(null);
              setMeasuringToolState(0);
              setMeasuringToolActive(!measuringToolActive);
            }}
            sx={{
              borderRadius: 0,
              color: "white",
              backgroundColor: measuringToolActive
                ? "primary.main"
                : "success.main",
            }}
          >
            <StraightenIcon />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
};

export const RampCanvas: React.FC<Props> = ({
  showReferenceImage,
  ramp,
  setRamp,
  readOnly = false,
}) => {
  const { postgrest } = useApi();
  const [activeMarking, setActiveMarking] = React.useState<Marking>({
    id: uuidv4(),
    ramp_id: ramp.id,
    type: null,
    geom: {
      type: "Polygon",
      coordinates: [[]],
    },
  });
  const [selectedPoint, setSelectedPoint] = React.useState<Position>(null);

  const [canvasHeight, setCanvasHeight] = React.useState<number>(600);
  const div = React.useCallback((node) => {
    if (node?.offsetWidth && node?.offsetHeight) {
      setCanvasHeight(node.offsetHeight);
    }
  }, []);

  const rampImage = useAsync(async () => {
    if (ramp.id && showReferenceImage && ramp?.reference_image) {
      const { data } = await postgrest
        .from("ramp")
        .select("reference_image")
        .eq("id", ramp.id)
        .single();
      return data.reference_image;
    }
    return null;
  }, [ramp.reference_image]);

  const canvasRef = React.createRef<HTMLCanvasElement>();
  const [startPoint, setStartPoint] = React.useState<{
    x: number;
    y: number;
  } | null>(null);
  const [endPoint, setEndPoint] = React.useState<{
    x: number;
    y: number;
  } | null>(null);

  const pixelsToFeetRatio = (ramp?.depth ?? 1) / canvasHeight;
  const canvasWidth = (canvasHeight * ramp?.width) / ramp?.depth;
  const pixelsToFeet = (px: number) => pixelsToFeetRatio * px;
  const feetToPixels = (feet: number) => feet / pixelsToFeetRatio;

  const theme = useTheme();

  const draw = React.useCallback(
    (ctx: CanvasRenderingContext2D) => {
      ctx.clearRect(0, 0, canvasWidth, canvasHeight);
      // draw stuff!
      if (ramp?.outline) {
        drawMarking(
          ctx,
          {
            id: uuidv4(),
            ramp_id: ramp.id,
            type: MarkingType.OUTLINE,
            geom: ramp.outline,
          },
          feetToPixels,
          readOnly
        );
      }
      // the active thing being marked up
      if (activeMarking?.type) {
        drawMarking(ctx, activeMarking, feetToPixels, readOnly);
      }
      for (const marking of ramp.markings) {
        drawMarking(ctx, marking, feetToPixels, readOnly);
      }
      if (selectedPoint) {
        const selectedPointInPixels = selectedPoint.map(feetToPixels);
        console.log("selectedPoint", selectedPoint, selectedPointInPixels);
        // ctx.lineTo(selectedPointInPixels[0], ctx.canvas.height - selectedPointInPixels[1]);
        ctx.beginPath();
        ctx.save();
        // draw a circle around the point in hot pink
        ctx.fillStyle = "hotpink";
        ctx.strokeStyle = "hotpink";
        ctx.lineWidth = 4;
        ctx.arc(
          selectedPointInPixels[0],
          ctx.canvas.height - selectedPointInPixels[1],
          10,
          0,
          2 * Math.PI
        );
        ctx.stroke();
        ctx.restore();
      }
    },
    [ramp, activeMarking, showReferenceImage, selectedPoint]
  );

  const handleMouseDown = (evt) => {
    const canvas = evt.target;
    const rect = canvas.getBoundingClientRect();
    const x = pixelsToFeet(Math.round(evt.clientX - rect.left));
    const y = ramp.depth - pixelsToFeet(Math.round(evt.clientY - rect.top));

    if (!activeMarking?.type) {
      // check to see if the user clicked on an existing outline point
      for (const point of ramp.outline.coordinates.flat()) {
        const dist = distance(point, [x, y]);
        if (dist < 10) {
          setSelectedPoint(point);
          return;
        }
      }
      if (selectedPoint) {
        setRamp({
          ...ramp,
          outline: {
            ...ramp.outline,
            coordinates: ramp.outline.coordinates.map((segment) =>
              segment.map((point) => {
                if (point === selectedPoint) {
                  return [x, y];
                }
                return point;
              })
            ),
          },
        });
        setSelectedPoint(null);
      }
      return;
    }

    if (
      activeMarking.type === MarkingType.REFERENCE_DISTANCE &&
      activeMarking.geom.coordinates[0].length > 1
    ) {
      return;
    }

    setActiveMarking({
      ...activeMarking,
      geom: {
        ...activeMarking.geom,
        coordinates: [activeMarking?.geom.coordinates[0].concat([[x, y]])],
      },
    });
  };

  const handleMouseMove = (evt) => {
    const canvas = evt.target;
    const rect = canvas.getBoundingClientRect();
  };

  const handleMouseExit = (evt) => {
    const canvas = evt.target;
    const rect = canvas.getBoundingClientRect();
    //
  };

  const backgroundColor = "transparent";

  const cursor = React.useMemo(() => {
    return readOnly ? "grab" : "crosshair";
  }, []);

  return (
    <Stack direction="column" spacing={1}>
      {!readOnly && (
        <RampCanvasToolbar
          activeMarking={activeMarking}
          setActiveMarking={setActiveMarking}
          onClickSave={() => {
            if (activeMarking.type === MarkingType.OUTLINE) {
              setRamp({
                ...ramp,
                outline: activeMarking.geom,
              });
            } else {
              setRamp({
                ...ramp,
                markings: ramp.markings.concat([activeMarking]),
              });
            }
            setActiveMarking({
              id: uuidv4(),
              ramp_id: ramp.id,
              type: activeMarking.type,
              geom: {
                type: "Polygon",
                coordinates: [[]],
              },
            });
          }}
          onClickDelete={() => {
            setActiveMarking({
              id: uuidv4(),
              ramp_id: ramp.id,
              type: null,
              geom: {
                type: "Polygon",
                coordinates: [[]],
              },
            });
          }}
        />
      )}
      <Box
        ref={div}
        sx={{
          cursor,
          position: "relative",
          backgroundColor,
        }}
      >
        <Canvas
          ref={canvasRef}
          width={canvasWidth}
          height={canvasHeight}
          draw={draw}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseExit}
          onMouseLeave={handleMouseExit}
          style={{
            backgroundImage: showReferenceImage && `url(${rampImage?.value})`,
            backgroundSize: showReferenceImage && "cover",
          }}
        />
      </Box>
    </Stack>
  );
};
